import { FC, useEffect, useState } from 'react';
import { HiMoon, HiSun } from 'react-icons/hi';

export type Theme = 'dark' | 'light';

export const DarkThemeToggle: FC = () => {
    const [theme, setTheme] = useState({'dark': false, 'light': true});
    //const [theme, setTheme] = useState<Theme>();

    useEffect(() => {
        if (
            localStorage.getItem('color-theme') === 'dark' ||
            (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
        ) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, []);

    useEffect(() => {
        if (theme) {
            localStorage.setItem('color-theme', theme);
            if (theme === 'dark') {
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }
        }
    }, [theme]);

    const toggleTheme = () => setTheme(theme === 'dark' ? 'light' : 'dark');

    return (
        <button
            type="button"
            className="absolute right-5 top-5 rounded-lg p-2.5 text-sm text-white hover:bg-violet-800 focus:outline-none focus:ring-4 focus:ring-violet-900 dark:text-white dark:hover:bg-violet-800"
            onClick={toggleTheme}
        >
            {theme === 'light' ? <HiMoon className="h-5 w-5" /> : <HiSun className="h-5 w-5" />}
        </button>
    );
};

export default DarkThemeToggle;