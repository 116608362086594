import React, { useState, useRef, useEffect } from "react";
import Papa from "papaparse";
import { getUrlParameter } from "./functions";

const WishData = () => {
  const [data, setData] = useState({});
  const hasRun = useRef(false)

  useEffect(() => {
    if (!hasRun.current) {
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vQoNOzveE0GZlfdYe5NZWzhBosuQmbDy_qQNh1fd16DfAtvlEkykhfq5HjdcrwOHbUnLkrc--hnUw_8/pub?output=csv", {
      download: true,
      header: true,
      complete: (results) => {
        const filter = getUrlParameter('hvem');
        console.log('woop')
        let data = Array.from(results.data).filter(item => (item.Status === 'Aktiv' && item.Hvem.includes('alle')));
        if(filter) {
          data = Array.from(results.data).filter(item => (item.Status === 'Aktiv' && item.Hvem.includes(filter)));
        }
        setData(data);
        hasRun.current = true
      },
    });
  }
  }, []);

  const wishes = Array.from(data);

const Image = ({foto}) => {
    const random = Math.floor(Math.random() * 15) + 1;
    const randomImage = `/img/placeholder-${random}.jpg`;
    return (
      <img alt={data.Hvad} src={foto ? foto : randomImage} className={foto ? 'max-h-40 w-full object-cover' : 'max-h-40 w-full object-cover grayscale-[90%]'} />
    );
};

  return (
    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 p-10">
      {wishes && (
        wishes.map((data, i) => (          
        <ul className="overflow-hidden shadow-lg rounded-lg h-90 w-60 md:w-80 m-auto">
          <li key={i} className="group p-5 bg-white dark:from-fuchsia-500 dark:to-violet-500 dark:bg-gradient-to-r hover:bg-gradient-to-r hover:from-fuchsia-500 hover:to-violet-500">
            {data.Link ? (
              <a href={!!data.Link && (data.Link)} className="w-full block h-full" target="_blank" rel="noreferrer">
                <Image foto={data.Foto} />
                <div className="w-full p-4">
                    <p className="text-gray-800 dark:text-white text-xl font-medium mb-2">
                      {data.Hvad}
                    </p>
                    {!!data.Beskrivelse && (
                      <p className="text-gray-800 dark:text-white font-light mb-2">
                        {data.Beskrivelse}
                      </p>
                    )}
                </div>
              </a>
            ): (
              <div href={!!data.Link && (data.Link)} className="w-full block h-full" target="_blank" rel="noreferrer">
                <Image foto={data.Foto} />
                <div className="w-full p-4">
                    <p className="text-gray-800 dark:text-white text-xl font-medium mb-2">
                      {data.Hvad}
                    </p>
                    {!!data.Beskrivelse && (
                      <p className="text-gray-800 dark:text-white font-light mb-2">
                        {data.Beskrivelse}
                      </p>
                    )}
                </div>
              </div>
            )}            
          </li>
        </ul>
          ))
        )}
    </ul>
  );
};
export default WishData;
