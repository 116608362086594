import WishData from "./WishData";
import DarkThemeToggle from "./components/DarkThemeToggle";
import 'flowbite';
import {getUrlParameter, capitalizeFirstLetter} from "./functions";

const filter = getUrlParameter('hvem');
let title = 'Ønsker til Engvænget 13'
let intro = 'Hvis du skulle have lyst til at finde en gave til os der bor på Engvænget 13, kan du her se et samlet overblik over vores fælles ønsker.';
if(filter) {
  title = `Ønsker for ${capitalizeFirstLetter(filter)}`;
  intro = `Hvis du skulle have lyst til at finde en gave til ${capitalizeFirstLetter(filter)}, er der her lidt inspiration.`;
}

function App() {
  return (
    <div className="App bg-white dark:bg-gray-900">
      <DarkThemeToggle />
      <div className="flex py-10 bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white">
        <div className="container mx-auto p-5 md:p-0 md:w-1/2">
          <h1 className="text-3xl font-bold">{title}</h1>
          <p className="">{intro}</p>
          <div className="grid gap-4 lg:grid-cols-4 mt-5">
            <a href="/" className={`w-50 rounded-lg p-2.5 text-sm focus:outline-none focus:ring-4 focus:ring-violet-900 ${filter ? "text-violet-800 bg-white hover:text-white hover:bg-violet-800" : "text-white bg-violet-800 hover:text-violet-800 hover:bg-white" } `}>Fælles</a>
            <a href="/?hvem=johan" className={`w-50 rounded-lg p-2.5 text-sm focus:outline-none focus:ring-4 focus:ring-violet-900 ${filter==='johan' ? "text-white bg-violet-800 hover:text-violet-800 hover:bg-white" : "text-violet-800 bg-white hover:text-white hover:bg-violet-800"} `}>Johan</a>
            <a href="/?hvem=henriette" className={`w-50 rounded-lg p-2.5 text-sm focus:outline-none focus:ring-4 focus:ring-violet-900 ${filter==='henriette' ? "text-white bg-violet-800 hover:text-violet-800 hover:bg-white" : "text-violet-800 bg-white hover:text-white hover:bg-violet-800"} `}>Henriette</a>
            <a href="/?hvem=mathias" className={`w-50 rounded-lg p-2.5 text-sm focus:outline-none focus:ring-4 focus:ring-violet-900 ${filter==='mathias' ? "text-white bg-violet-800 hover:text-violet-800 hover:bg-white" : "text-violet-800 bg-white hover:text-white hover:bg-violet-800"} `}>Mathias</a>
          </div>
        </div>
      </div>
      <WishData />
    </div>
  );
}

export default App;
